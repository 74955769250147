<template>
  <div class="main">
    <CoreLogo />
    <slot />
    <CoreAppFooter />
  </div>
</template>

<script setup lang="ts"></script>
<style>
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
}

.main {
  min-height: 100%;
  display: grid;
  grid-template-rows: 8em auto 200px;
}
.cardimg {
  border-radius: 10px;
}
</style>
